/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { INIT } from './actions.js';

const savePaymentRequest = paymentRequest => {
  window.localStorage.setItem('paymentRequest', JSON.stringify(paymentRequest));
}

const loadPaymentRequest = () => {
  let pr = window.localStorage.getItem('paymentRequest');
  return pr ? JSON.parse(pr) : null;
}

export const persistence = store => next => action => {
  let result = next(action)
  if (action.type === INIT) {
    store.getState().request = loadPaymentRequest();
    store.getState().result = 'Press a "Pay" button.';
  } else {
    savePaymentRequest(store.getState().request);
  }
  return result;
}
